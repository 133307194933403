import { useState } from 'react'
import { Skeleton } from '@mui/material'
import { ArrowLeftStartOnRectangleIcon, Bars3Icon, ChevronDownIcon, UserIcon } from '@heroicons/react/24/outline'

import type { User } from '../../user/types'
import { useTranslation } from 'react-i18next'
import { getFullName } from '@shared/helpers/utils'
import { useProfileOwnerQuery } from '../../user/queries'
import { useAuth } from '../../authentication/ui/AuthContainer'
import { publicFolder } from '@shared/helpers/pathBuilder'
import { IconButton } from '@shared/components/base/IconButton'
import Avatar from '@shared/components/base/Avatar'
import { useNavigate } from 'react-router'

type Props = {
    toggleSidebar: () => void
}

export function AppBar({ toggleSidebar }: Props) {
    const { data: user = {} as User, isLoading } = useProfileOwnerQuery()
    const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false)

    return (
        <div
            className="px-md fixed top-0 z-20 flex w-full items-center justify-between bg-white shadow-md"
            style={{ height: 'var(--app-bar-height)' }}
        >
            <div className="gap-md flex items-center">
                <IconButton onClick={toggleSidebar}>
                    <Bars3Icon className="size-7" />
                </IconButton>
                <img
                    className="hidden h-[24px] md:block"
                    src={publicFolder.images.logoColorCaptello}
                    alt="captello-logo"
                />
                <img className="block h-[24px] md:hidden" src={publicFolder.images.logoImageOnly} alt="captello-logo" />
            </div>

            <div className="gap-md flex items-start">
                {isLoading ? <LoadingSkeletons /> : <UserBasicInfo user={user} />}
                <IconButton onClick={() => setIsOpenDropdownMenu(!isOpenDropdownMenu)}>
                    <ChevronDownIcon className="size-5" />
                </IconButton>

                {isOpenDropdownMenu && <DropdownMenu />}
            </div>
        </div>
    )
}

const UserBasicInfo = ({ user }: { user: User }) => {
    const fullName = getFullName(user.firstName, user.lastName)

    return (
        <>
            <div className="size-11 rounded-full">
                <Avatar fullName={fullName} src={user.fileUserProfilePicture || publicFolder.images.avatar} size={44} />
            </div>

            <div className="hidden flex-col md:flex">
                <span className="text-secondary-900 !font-semibold">{fullName}</span>
                <span className="text-secondary-300 text-sm">{user.title}</span>
            </div>
        </>
    )
}

const LoadingSkeletons = () => {
    return (
        <>
            <Skeleton variant="circular" sx={{ height: '48px', width: '48px' }} />
            <Skeleton variant="rectangular" sx={{ height: '44px', width: '180px' }} />
        </>
    )
}

const DropdownMenu = () => {
    const { logout } = useAuth()
    const { t } = useTranslation()
    const navigate = useNavigate()

    function navigateToUserInfo() {
        navigate('/settings')
    }

    const items = [
        { name: 'accountInfo', icon: <UserIcon />, onClick: navigateToUserInfo },
        // { name: 'changePassword', icon: <LockClosedIcon /> },
        { name: 'logout', icon: <ArrowLeftStartOnRectangleIcon />, onClick: logout },
    ]

    return (
        <div className="gap-md p-md absolute top-12 right-6 grid rounded-md bg-white shadow-lg">
            {items.map((item, index) => {
                return (
                    <div
                        className="gap-sm p-sm hover:bg-secondary flex cursor-pointer items-center bg-transparent hover:rounded-lg"
                        onClick={item.onClick}
                        key={index}
                    >
                        <span className="size-5">{item.icon}</span>
                        <span className="text-secondary-900">{t(item.name)}</span>
                    </div>
                )
            })}
        </div>
    )
}

import { ReactElement, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'

import { AppBar } from './AppBar'
import { Sidebar } from './Sidebar'
import { SidebarMobile } from './SidebarMobile'
import { useIsTablet } from '@shared/hooks/useBreakpoint'
import { Spinner } from '@shared/components/base/Spinner'

export function PageLayout() {
    const isTablet = useIsTablet()
    const [isSidebarOpen, setIsSidebarOpen] = useState(!isTablet)

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const sidebarWidth = isSidebarOpen ? 'var(--side-bar-width-open)' : 'var(--side-bar-width-closed)'

    useEffect(() => {
        if (isTablet) setIsSidebarOpen(false)
    }, [isTablet])

    return (
        <div className="h-screen w-full">
            <AppBar toggleSidebar={toggleSidebar} />

            <AutoSizer disableHeight>
                {({ scaledWidth }) => (
                    <div className="flex" style={{ paddingTop: 'var(--app-bar-height)', width: scaledWidth }}>
                        {isTablet ? (
                            <SidebarMobile isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                        ) : (
                            <Sidebar isSidebarOpen={isSidebarOpen} />
                        )}
                        <ScrollToTopWrapper>
                            <div
                                className="w-full p-4 md:py-5"
                                style={{
                                    marginLeft: isTablet ? 0 : sidebarWidth,
                                    height: 'calc(100svh - (var(--app-bar-height) + var(--spacing) * 5))',
                                }}
                            >
                                <Suspense fallback={<LoadingSpinner />}>
                                    <Outlet />
                                </Suspense>
                            </div>
                        </ScrollToTopWrapper>
                    </div>
                )}
            </AutoSizer>
        </div>
    )
}

function LoadingSpinner() {
    return (
        <div className="grid place-items-center" style={{ height: 'calc(100vh - var(--app-bar-height))' }}>
            <Spinner />
        </div>
    )
}

function ScrollToTopWrapper({ children }: { children: ReactElement }) {
    const location = useLocation()

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, [location.pathname])

    return children
}

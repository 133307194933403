import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import type { PropsWithChildren } from 'react'
import CssBaseline from '@mui/material/CssBaseline'

const options = {
    cssVariables: { cssVarPrefix: '' },
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    colorSchemes: {
        light: {
            palette: {
                background: { default: '#FAFAFA' },
                success: {
                    main: '#32CD32',
                    contrastText: '#FFFFFF',
                },
                error: { main: '#DC143C', light: '#FFF2F2' },
                primary: {
                    main: '#FF0000',
                    5: '#FFF0F0',
                    50: '#FFE5E5',
                    700: '#990000',
                },
                secondary: {
                    light: '#FAFAFA',
                    main: '#F2F2F2',
                    dark: '#E6E6E6',
                    200: '#CCCCCC',
                    300: '#B3B3B3',
                    400: '#999999',
                    500: '#808080',
                    600: '#666666',
                    700: '#4D4D4D',
                    800: '#333333',
                    900: '#1A1A1A',
                },
            },
        },
    },
    shape: {
        borderRadius: 8,
    },
}

export function ThemeProvider({ children }: PropsWithChildren) {
    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(options)}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </StyledEngineProvider>
    )
}

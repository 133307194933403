import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@shared/components/base/Dialog'
import { Button } from '@shared/components/base/Button'
import { createStore, useStore } from 'zustand'

export function ConfirmationDialogContainer() {
    const {
        data: { title, message, mainAction, onConfirm, onClose, showCloseButton },
        exitConfirmDialog,
    } = useConfirmDialog()

    const { t } = useTranslation()
    if (!onConfirm) return null

    const isOpen = Boolean(onConfirm)

    const handleOnConfirm = () => {
        onConfirm()
        exitConfirmDialog()
    }

    const handleOnClose = () => {
        onClose?.()
        exitConfirmDialog()
    }

    return (
        <Dialog open={isOpen} onClose={handleOnClose} maxWidth="xs">
            <DialogTitle className="pb-2 text-lg !font-semibold">{t(title)}</DialogTitle>
            <DialogContent className="pb-md">{t(message)}</DialogContent>
            <DialogActions className="px-md pb-md">
                {showCloseButton && (
                    <Button color="secondary" onClick={handleOnClose}>
                        {t('cancel')}
                    </Button>
                )}

                <Button onClick={handleOnConfirm}>{t(mainAction)}</Button>
            </DialogActions>
        </Dialog>
    )
}

type ConfirmDialogStoreType = {
    title: string
    message: string
    mainAction: string
    showCloseButton: boolean
    onConfirm: undefined | (() => void)
    onClose?: () => void
}
export const confirmDialogStore = createStore<ConfirmDialogStoreType>(() => ({
    title: '',
    message: '',
    mainAction: '',
    showCloseButton: true,
    onConfirm: undefined,
    onClose: undefined,
}))

type OpenConfirmDialogType = {
    title?: string
    message?: string
    mainAction?: string
    showCloseButton?: boolean
    onConfirm: undefined | (() => void)
    onClose?: () => void
}

export function useConfirmDialog() {
    const data = useStore(confirmDialogStore, (state) => state)

    return {
        data,
        exitConfirmDialog: () => {
            confirmDialogStore.setState({
                title: '',
                message: '',
                mainAction: '',
                showCloseButton: false,
                onConfirm: undefined,
                onClose: undefined,
            })
        },
        openConfirmDialog: (data: OpenConfirmDialogType) => {
            const {
                title = 'confirmation',
                message = 'confirmationDialogContentMessage',
                mainAction = 'confirm',
                showCloseButton = true,
                ...rest
            } = data
            confirmDialogStore.setState({ title, message, mainAction, showCloseButton, ...rest })
        },
    }
}

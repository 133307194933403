import { forwardRef, ReactElement } from 'react'
import { Alert as MuiAlert } from '@mui/material'

interface AlertProps {
    icon?: ReactElement
    severity: 'success' | 'info' | 'warning' | 'error'
    variant?: 'filled' | 'outlined' | 'standard'
    description?: string
    action?: ReactElement
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    const { icon, variant = 'filled', action, severity, description } = props

    return (
        <MuiAlert
            ref={ref}
            severity={severity}
            icon={icon}
            variant={variant}
            action={action}
            elevation={6}
            className="px-2 !font-normal !text-white"
        >
            {description}
        </MuiAlert>
    )
})
Alert.displayName = 'Alert'

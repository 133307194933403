import { NuqsAdapter } from 'nuqs/adapters/react'
import { ReactQueryProvider } from '@shared/providers/ReactQueryProvider'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AppRoutes } from './AppRoutes'
import { mockServer } from './mocks'
import { ThemeProvider } from './theme'
import { AuthProvider } from './features/authentication/ui/AuthContainer'
import { SnackbarProvider } from '@shared/providers/SnackbarProvider'
import { ConfirmationDialogContainer } from '@shared/providers/ConfiramtionDialogProvider'

import * as Sentry from '@sentry/react'

import './i18n'
import './index.css'

Sentry.init({
    dsn: 'https://169a40d5a9b6bf44a1a2ab5e183c0db6@o1148737.ingest.us.sentry.io/4508200797667328',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    environment: import.meta.env.MODE,
    enabled: import.meta.env.MODE !== 'development',
})

const rootElement = document.getElementById('root') as HTMLElement | null

if (!rootElement) {
    throw 'Root element not found'
}

const root = createRoot(rootElement)

mockServer.finally(() => {
    root.render(
        <StrictMode>
            <NuqsAdapter>
                <ReactQueryProvider>
                    <ThemeProvider>
                        <SnackbarProvider>
                            <AuthProvider>
                                <AppRoutes />
                            </AuthProvider>
                        </SnackbarProvider>
                        <ConfirmationDialogContainer />
                    </ThemeProvider>
                </ReactQueryProvider>
            </NuqsAdapter>
        </StrictMode>
    )
})

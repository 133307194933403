import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { ReactNode } from 'react'

import { isProduction, isStorybookEnv } from '@shared/helpers/utils'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // query options
            retry: 2,
            refetchOnWindowFocus: false,
            staleTime: 10000,
            retryDelay: 500,
        },
    },
})

export function ReactQueryProvider(props: { children: ReactNode | ReactNode[] }): ReactNode {
    const { children } = props
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {!isProduction() && !isStorybookEnv() ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </QueryClientProvider>
    )
}
